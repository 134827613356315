<script lang="ts">
	import { override } from '$lib/utils/classnames'
	import { fixTitleCharacters } from '$lib/utils/title-case'

	export let title: string = ''

	let className = ''
	export const as = 'span'
	export { className as class }
</script>

<div
	class={override(
		'text-sm font-normal tracking-wide leading-4 sm:text-base xl:text-lg 2xl:text-lg ',
		className,
	)}
>
	<slot>{fixTitleCharacters(title)}</slot>
</div>
