<script lang="ts">
	import { override } from '$lib/utils/classnames'
	import { twMerge } from 'tailwind-merge'
	import MainTitle from './MainTitle.svelte'
	import { createEventDispatcher } from 'svelte'

	let className = ''
	export { className as class }
	let buttonClassName = ''
	export { buttonClassName as buttonClass }
	export const enableShareAction = false
	export let isIntersecting = true

	const dispatch = createEventDispatcher()

	function handleClick() {
		dispatch('click')
	}

	export let title: string = ''

	$: computedClassName = twMerge(
		override(
			'flex items-center justify-between sticky top-[64px] lg:top-[116px] duration-200 transition-shadow transform-gpu will-change-transition pt-7 pb-4 h-7 lg:h-10 lg:pt-10 lg:pb-5 sm:px-0 z-10 border-b bg-white dark:bg-brand-gray-5 border-brand-gray-2 text-black dark:border-brand-gray-4/20 dark:text-white',
			className,
		),
	)

	$: computedButtonClassName = twMerge(
		override(
			'cursor-pointer text-brand-gray-5 hover:text-black dark:hover:text-white dark:text-brand-gray-2 transition-colors',
			buttonClassName,
		),
	)
</script>

<div class={override(`${isIntersecting ? 'sticky-header-shadow' : ''}`, computedClassName)}>
	{#if title}
		<button class={computedButtonClassName} on:click={handleClick}>
			<MainTitle {title} />
		</button>
	{:else}
		<MainTitle><slot name="title" /></MainTitle>
	{/if}
	<slot />
</div>
